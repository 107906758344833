import Col from 'react-bootstrap/Col';
import SEO from './SEO';

const Contact = () => {
  return (
    <>
      <SEO title="TOIA - Contact" description="Get in touch with me." name="TOIA" type="page" />

      <Col className="contact text-center">
        <p>
          Are you interested in <b>collabs</b>, <b>comissions</b>, or <b>acquire a piece of art?</b>
        </p>
        <p className="text-center">
          Get in touch:{' '}
          <a href="mailto:victoriamzur@gmail.com" target="blank">
            victoriamzur@gmail.com
          </a>
        </p>
        <p className="text-center">
          Instagram:{' '}
          <a href="https://www.instagram.com/toia.ceramic/" target="blank">
            @toia.ceramic
          </a>
        </p>
        <p className="text-center">
          Phone Number: <a href="tel:+610476119477">+61 0476 119 477</a>
        </p>
      </Col>
    </>
  );
};

export default Contact;
