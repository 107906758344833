import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import SEO from './SEO';
import Work from './Work';
const Shop = () => {
  const [work, setWork] = useState([]);

  useEffect(() => {
    fetch('/portfolio.json')
      .then((res) => res.json())
      .then((json) => {
        setWork(json);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <SEO title="TOIA - Shop" description="Ceramic Artist." name="TOIA" type="page" />
      <Col className="shop">
        <h1 className="text-center">Coming soon...</h1>
        <p className="text-center">
          the following items are currently available, <br></br>If you are interested in buy something please <a href="/contact">get in touch.</a>
        </p>
      </Col>
      <div className="row m-auto mt-5">
        {work
          .filter((work) => work.available == true)
          .map((work) => {
            return <Work key={work.id} id={work.id} slug={work.slug} thumbnail={work.thumbnail} image={work.image} title={work.title} available={work.available} category={work.category} />;
          })}
      </div>
    </>
  );
};

export default Shop;
