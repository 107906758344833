import Col from 'react-bootstrap/Col';
import SEO from './SEO';
import { useEffect } from 'react';

const WorkshopsPottery = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://form.jotform.com/jsform/241122845706857';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <SEO title="TOIA - Pottery Workshop" description="Pottery Workshop." name="TOIA" type="page" />

      <Col className="workshop text-center">
        <div></div>
      </Col>
    </>
  );
};

export default WorkshopsPottery;
