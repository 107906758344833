import Col from 'react-bootstrap/Col';
import SEO from './SEO';
import { useEffect } from 'react';

const Workshops = () => {
  return (
    <>
      <SEO title="TOIA - Workshops" description="Find my workshops here." name="TOIA" type="page" />

      <Col className="workshop text-center">
        <div>
          <h1 className="">Upcoming Workshops in Perth</h1>
          <p>-</p>
          <h1>
            <a href="workshops/pottery" className="workshop-link">
              Pottery Workshop at Luca Cafe - 28/09
            </a>
          </h1>
        </div>
      </Col>
    </>
  );
};

export default Workshops;

/* <h1>
            <a href="workshops/jewellery" className="workshop-link">
              Jewellery Workshop - 25/07
            </a>
          </h1>
*/
