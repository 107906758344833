import WorkRelatedContainer from "./WorkRelatedContainer";
import SEO from "./SEO";
import { Row, Col, Carousel } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import ModalImage from "react-modal-image";

const WorkDetail = ({ work, allworks }) => {
  return (
    <>
      <SEO
        title={"TOIA - " + work.title}
        description={work.description}
        name="Victoria Martinez Zurbano"
        type="article"
      />
      <h1>{work.title}</h1>

      <small>category: {work.category}</small>
      {work.available ? <div className="for-sale">For Sale</div> : ""}
      <p className="mt-4 work-description">{work.description}</p>
      {work.available ? (
        <div className="product-available">
          This product it's available for sale, if you are interested to acquire
          it, <a href="/contact">please contact me.</a>
        </div>
      ) : (
        ""
      )}
      <BrowserView>
        <Row className="gallery">
          {work.image.map((image) => {
            return (
              <Col>
                <ModalImage
                  small={"/" + image}
                  large={"/" + image}
                  key={Math.random()}
                />
              </Col>
            );
          })}
        </Row>
      </BrowserView>
      <MobileView>
        <Carousel>
          {work.image.map((image) => {
            return (
              <Carousel.Item key={image.toString()}>
                <img src={"/" + image} className="d-block w-100 img-fluid" />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </MobileView>
      <WorkRelatedContainer
        currentId={work.id}
        currentCat={work.category}
        allworks={allworks}
      />
    </>
  );
};

export default WorkDetail;
