import { Col, Row } from 'react-bootstrap';
import SEO from './SEO';

const Bio = () => {
  return (
    <>
      <SEO title="TOIA - Bio" description="Victoria Martinez Zurbano Ceramist." name="TOIA" type="article" />
      <Col className="bio">
        <p>Victoria Martínez Zurbano was born in 1992 in Corrientes, Argentina.</p>
        <p>
          Her experiencie at the University of Architecture at UNNE (4 years without finishing it) provided her with enriching perspective to her approach to art, through the development of
          design thinking.
        </p>
        <p> Her knowledge and skills in plastic arts have been built through self-learning, always encouraged by her artist mother, Yasí Avalos.</p>
        <p>
          She attended workshops by out local leading teachers such as José Mizdraji and Mabel Vilchez. She took part in an artwork clinic in 2007 with Carolina Antoniadis and in 2010 with
          Diana Aisenberg, which triggered new creative and conceptual paths.
        </p>
        <p>
          {' '}
          In 2010 she was part of the management team and promoter of "La Cárcova" itinerant art gallery.<br></br>
          Ceramics came into her life as an opportunity for transformation in 2015, discovering this Fine art with María Inés Rossi and clinging to it with master Villafañe. Since 2012, she
          has coordinated the cultural-ecological space "La Pépinière" and "Taller de la Luna Llena" (Her Studio and place of workshops) where she and her mother seek to recover traditional
          techniques and develop new identities through the craft.
        </p>
        <p>
          During the years 2015 – 2022, she participated in several collective exhibitions: End of the year exhibition of Luna Llena Taller de cerámica (2017, 2018, 2021, 2022), Muestra
          Colectiva del ENACER 2018 (Santa Fé), ArteCo ( Corrientes, 2021), Exhibition of local ceramics at Casa Martínez ( Corrientes, 2021), Tembe'y Orilla (Corrientes 2022), Exhibition of
          pre-Hispanic root pieces, Casa Iberá (2022).
        </p>
        <p>
          During the year 2022, she led the workshop "Guarani pre-Hispanic root ceramics" with the aim of revaluing ancestral local techniques, focusing on container morphologies of the
          Guarani culture, together with Yasí Avalos, at the Museum of Archeology and Anthropology of Corrientes.
        </p>
      </Col>
      <Col md={6}>
        <img src="assets/images/toia.jpg" className="img-fluid bio__image"></img>
      </Col>
      <Row>
        <Col className="mb-5">
          <h4 className="mt-5">About my work</h4>
          <p>
            I create under the deep search of the responsibility that the transformation of clays into enduring objects implies, accompanying the externalized translation of a constant
            internal symbiosis, and the constant exchange and training with colleagues and Masters of cerámics.
          </p>
          <p>
            In the workshops that I propose, I seek to work with traditional techniques, developing new identities through the ceramic language, investigating the deep gestures of each
            person to achieve pieces with character and authenticity.
          </p>

          <p>
            In these times that alert us, that challenge our sensitivity in our bones, I feel it necessary to focus on the moment, resuming rituals that give us back our belonging. Leaving
            aside the utility itself, to rethink the relationship we have with the materials and the conjunction with our own stories.
          </p>

          <p>
            My work insists on highlighting spaces with presences. I propose objects that reflect a growing sensitivity, a unique history through sensible gestures, our desire to beautify
            the days, create sparkles in time through magical, special materials.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Bio;
