import React from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite.min.css";
import "./index.css";
import App from "./components/App";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

//Initialize GA4
ReactGA.initialize("G-NTL8HFT7YG");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
