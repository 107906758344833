import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link } from 'react-router-dom';

let handleCollapse = () => {
  var nav = document.getElementById('navbarNav');
  var btn = document.getElementById('navbarBtn');
  nav.classList.remove('show');
  btn.classList.add('collapsed');
};

function Header() {
  return (
    <>
      <Navbar collapseOnSelect bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            TOIA.
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={NavLink} to="/bio" onClick={handleCollapse}>
                BIO
              </Nav.Link>
              <Nav.Link as={NavLink} to="/work" onClick={handleCollapse}>
                WORK
              </Nav.Link>
              <Nav.Link as={NavLink} to="/shop" onClick={handleCollapse}>
                SHOP
              </Nav.Link>
              <Nav.Link as={NavLink} to="/workshops" onClick={handleCollapse}>
                WORKSHOPS
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contact" onClick={handleCollapse}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
